import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import ArtView from "@/views/ArtView.vue";
import FotoView from "@/views/FotoView.vue"
import MusicView from "@/views/MusicView.vue";
import GamesView from "@/views/GamesView.vue";
import MapView from "@/views/MapView.vue";
import WeatherView from "@/views/WeatherView.vue";
import Error404 from "@/views/Error404.vue";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/art",
    name: "art",
    component: ArtView,
  },
  {
    path: "/foto",
    name: "foto",
    component: FotoView,
  },
  {
    path: "/music",
    name: "music",
    component: MusicView,
  },
  {
    path: "/games",
    name: "games",
    component: GamesView,
  },
  {
    path: "/map",
    name: "map",
    component: MapView,
  },
  {
    path: "/weather",
    name: "weather",
    component: WeatherView,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error-404",
    component: Error404,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
